<template>
  <v-dialog
    v-model="isOpen"
    :max-width="800"
    :persistent="pending"
    class="ExportTasksCreateDialog"
  >
    <v-sheet
      :height="pending ? 276: (errorMessage ? 'auto' : 588)"
      :min-height="errorMessage ? 276 : 0"
      class="ExportTasksCreateDialog__card d-flex column text-truncate"
    >
      <CommonIconButton
        absolute
        top="16"
        right="28"
        icon="mdi-close"
        :disabled="pending"
        @click="isOpen = false"
      />

      <div
        v-if="pending || errorMessage"
        class="ExportTasksCreateDialog__pending"
      >
        <h3 class="ExportTasksCreateDialog__title display-2">
          {{ errorMessage ? $t('integration.SendingToJiraFailed') : $t('integration.SendingToJira') }}
          <v-icon
            v-if="errorMessage"
            color="error"
            :size="30"
            class="ml-2"
            v-text="'mdi-emoticon-sad-outline'"
          />
        </h3>

        <div
          v-if="errorMessage"
          style="margin-top: 10px; margin-bottom: 32px; white-space: normal; text-align: center"
          v-text="errorMessage"
        />

        <div style="position: relative; margin-top: 35px; margin-bottom: 32px">
          <PortalTarget name="ajax-bar" />
        </div>

        <div class="ExportTasksCreateDialog__footer">
          <v-btn
            v-if="errorMessage"
            class="mr-2"
            depressed
            color="primary"
            @click="exportIssues"
          >
            {{ $t('integration.Retry') }}
          </v-btn>
          <v-btn
            outlined
            color="primary"
            @click="isOpen = false"
          >
            {{ $t('integration.Cancel') }}
          </v-btn>
        </div>
      </div>
      <ExportTasksPreview
        v-else
        :visible="isOpen"
        :pending="pending"
        :project-id="projectId"
        :issues="filteredIssues"
        :integration-id="integration.id"
        :issues-required-fields="requiredFieldInIssue"
        :issues-non-required-fields="nonRequiredFieldInIssue"
        :error-in-issues="errorInIssues"
        @submit="exportIssues"
        @close="isOpen = false"
        @change-required-issue-field="changeIssuesRequiredFields($event)"
        @change-non-required-issue-field="changeIssuesNonRequiredFields($event)"
        @apply-set-value="applySetValue($event)"
        @apply-set-value-non-required="applySetValueNonRequired($event)"
      />
    </v-sheet>
  </v-dialog>
</template>

<script>
import lodash from 'lodash/fp'

import { resolveErrorMessage } from '../helpers'

import Issue from '../store/orm/issue'
import IssueExportRecord from '../store/orm/issueExportRecord'

import ajaxPortWhen from '../mixins/ajaxPortWhen'

import ExportTasksPreview from './ExportTasksPreview'

export default {
  name: 'ExportTasksCreateDialog',

  components: {
    ExportTasksPreview,
  },

  mixins: [
    ajaxPortWhen(function() {
      return this.isOpen && this.pending
    }),
  ],

  props: {
    dialogInstance: { type: Object, required: true },
    projectId: { type: String, default: null },
    issueIds: { type: Array, required: true },
    integration: { type: Object, default: null },
  },

  data() {
    return {
      pending: false,
      errorMessage: null,
      requiredFieldInIssue: {},
      nonRequiredFieldInIssue: {},
      errorInIssues: null,
    }
  },

  computed: {
    isOpen: {
      get() { return this.dialogInstance.isOpen },
      set(isOpen) { this.dialogInstance.onDialogModelInput(isOpen) },
    },

    meta() { return this.integration?.meta || null },

    filteredIssues() {
      const { issues, errorInIssues } = this

      if (!errorInIssues) return issues

      const issueWithErrorIds = errorInIssues.map(error => error.localIssueID || error.id)

      return issues.filter(issue => issueWithErrorIds.includes(issue.id))
    },

    issues() {
      const { issueIds } = this
      return Issue.query().whereIdIn(issueIds).all()
    },
  },

  watch: {
    meta: {
      immediate: true,
      handler() { this.defineJiraFieldsForIssues() },
    },
  },

  methods: {
    applySetValue(e) {
      const { requiredFieldInIssue } = this
      const currentIssueFieldValues = requiredFieldInIssue[e.issueId].fieldsValues

      Object.values(requiredFieldInIssue).map(issueField => {
        if (issueField.id !== e.issueId) issueField.fieldsValues = lodash.cloneDeep(currentIssueFieldValues)
      })
    },

    applySetValueNonRequired(e) {
      const { nonRequiredFieldInIssue } = this
      const currentIssueFieldValues = nonRequiredFieldInIssue[e.issueId].fieldsValuesNonRequired

      Object.values(nonRequiredFieldInIssue).map(issueField => {
        if (issueField.id !== e.issueId) issueField.fieldsValuesNonRequired = lodash.cloneDeep(currentIssueFieldValues)
      })
    },

    changeIssuesRequiredFields(e) {
      const { requiredFieldInIssue } = this
      const { fieldName, issueId, value } = e

      requiredFieldInIssue[issueId].fieldsValues.map(field => {
        if (field.field === fieldName) field.value = value
      })
    },

    changeIssuesNonRequiredFields(e) {
      const { nonRequiredFieldInIssue } = this
      const { fieldName, issueId, value } = e

      nonRequiredFieldInIssue[issueId].fieldsValuesNonRequired.map(field => {
        if (field.field === fieldName) field.value = value
      })
    },

    exportIssues() {
      const {
        $store,
        integration: { id: integrationId },
        projectId,
        requiredFieldInIssue,
        nonRequiredFieldInIssue,
      } = this

      const issuesFields = lodash.merge(requiredFieldInIssue, nonRequiredFieldInIssue)
      Object.values(issuesFields).map(item => {
        item.fieldsValuesNonRequired.map(nonRequired => {
          if (!lodash.isEmpty(nonRequired.value)) item.fieldsValues.push(nonRequired)
        })
        this.$delete(item, 'fieldsValuesNonRequired')
      })

      const actionPayload = {
        integrationId,
        issues: Object.values(issuesFields),
        projectId,
      }

      this.pending = true
      this.errorMessage = null

      $store.dispatch('integration/exportToJira', actionPayload)
        .then((res) => {
          const success = res.filter(exportIssueData => exportIssueData.state !== 'FAILED')
          const errors = res.filter(exportIssueData => exportIssueData.state === 'FAILED')
          this.onTasksExported(success, errors)
        })
        .catch(async e => { this.errorMessage = await resolveErrorMessage(e) })
        .finally(() => { this.pending = false })
    },

    onTasksExported(success, errors) {
      let message = ''
      if (success.length) message += `${this.$t('integration.ExportSuccessM')} ${this.$tc('integration.issueCountN', success.length)}`
      if (success.length && errors.length) message += ' | '
      if (errors.length) message += `${this.$t('integration.ExportErrorM')} ${this.$tc('integration.issueCountN', errors.length)}`
      this.$emit('created', { showSnackbar: { message }, hasErrors: errors.length > 0 })

      this.errorMessage = null

      if (success.length) {
        for (const exportIssueData of success) {
          // TODO: move this manipulation to store
          if (!exportIssueData.exportRecord) return
          const exportRecord = exportIssueData.exportRecord
          exportRecord.issueId = exportIssueData.localIssueID
          IssueExportRecord.insertOrUpdate({
            data: exportRecord,
          })
        }
      }

      if (!errors.length) {
        this.isOpen = false
      } else {
        this.errorInIssues = errors
      }
    },

    defineJiraFieldsForIssues() {
      const { issues, integration, requiredFieldInIssue, nonRequiredFieldInIssue, meta } = this
      const fieldsValues = []
      const fieldsValuesNonRequired = []

      if (!issues.length) return {}

      meta?.requiredFields?.map(item => {
        fieldsValues.push({
          field: item.name,
          value: integration?.fieldsDefaultValues?.find(field => field.field === item.name)?.value || item?.defaultValue || [],
        })
      })

      meta?.nonRequiredFields?.map(item => {
        fieldsValuesNonRequired.push({
          field: item.name,
          value: integration?.fieldsDefaultValues?.find(field => field.field === item.name)?.value || item?.defaultValue || [],
        })
      })

      issues.forEach(issue => {
        this.$set(requiredFieldInIssue, issue.id, lodash.cloneDeep({
          fieldsValues,
          id: issue.id,
        }))

        this.$set(nonRequiredFieldInIssue, issue.id, lodash.cloneDeep({
          fieldsValuesNonRequired,
          id: issue.id,
        }))
      })
    },
  },
}
</script>

<style lang="sass" scoped>
.ExportTasksCreateDialog
  &__card
    position: relative
    overflow: hidden
    text-overflow: ellipsis
    transition: all 200ms ease-in

  &__pending
    width: 100%
    padding: 82px 48px 0
    display: flex
    flex-direction: column

  &__title
    display: flex
    align-items: center
    justify-content: center
    width: 100%

  &__footer
    padding-bottom: 32px
    display: flex
    justify-content: flex-end
    margin-top: auto
</style>
